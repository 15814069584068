import React, { useState, useEffect } from 'react';
import authentication from 'services/auth';

export const authContext = React.createContext();
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(window.localStorage.getItem('token'));
  return (
    <authContext.Provider value={{ auth, setAuth }}>
      {children}
    </authContext.Provider>
  );
};
