import axios from "axios";

export default {
  login: async (data) => {
    let res = await axios.post("/user/login", data);
    window.localStorage.setItem("token", res.data.token);
    return res.data || [];
  },

  isAuthenticated: async () => {
    const token = window.localStorage.getItem("token");
    try {
      let res = await axios.post("/user/isAuthenticated", { token: token });
      return res.data;
    } catch (e) {
      window.localStorage.removeItem("token");
      return false;
    }
  },

  logout: () => {
    if (window.localStorage.getItem("token") !== null) {
      window.localStorage.removeItem("token");
    }
  },
};
