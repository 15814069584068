import React, { useState, useEffect, useContext } from 'react';
import { Redirect, Route } from 'react-router';

import authentication from '../services/auth';
import { authContext } from './authProvider';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { auth, setAuth } = useContext(authContext);

  useEffect(() => {
    (async function () {
      const res = await authentication.isAuthenticated(
        window.localStorage.getItem('token')
      );

      if (!res) return setAuth(false);
      setAuth(true);
    })();
  });

  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default ProtectedRoute;
