export const mainLinks = [
  {
    link: '/',
    text: 'Home',
  },
];

export const legalLinks = [
  { link: '/impressum', text: 'Impressum' },
  { link: '/datenschutz', text: 'Datenschutz' },
];
